 const dev={
    REACT_APP_SERVICE_ID:"service_usb22uk",
    REACT_APP_USER_ID: "user_aFtC8XUYSPgHssziEIaP8"
}

const prod={
    REACT_APP_SERVICE_ID:"service_usb22uk",
    REACT_APP_USER_ID: "user_aFtC8XUYSPgHssziEIaP8"
}
const config=process.env.NODE_ENV=='development'?dev:prod
export default  config