import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ButtonBase from "@material-ui/core/ButtonBase";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import emailjs from "emailjs-com";
import config from "./../config.js";

import logo from "./../assets/logo-backgr.png";
import icon from "./../assets/icon.svg";

const useStyles = makeStyles((theme) => ({
  section: { position: "relative", padding: "100px 0", textAlign: "center" },
  logo: {
    position: "absolute",
    height: "50%",
    zIndex: -100,
    bottom: 100,
    "@media (max-width:600px)": { bottom: 350 },
  },
  logoLeft: {
    left: -200,
  },
  logoRight: {
    right: -200,
    transform: "scaleX(-1)",
  },
  title: {
    position: "relative",
    textAlign: "right",
    paddingRight: 24,
    width: "max-content",
    alignSelf: "flex-end",
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: -24,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    "@media (max-width:600px)": { marginBottom: 36 },
  },
  card: {
    margin: "60px 0",
    "@media (max-width:600px)": { margin: "30px 0" },
  },
  icon: {
    height: 40,
  },
  form: { "@media (max-width:600px)": { marginTop: 40 } },
  input: {
    maxWidth: 400,
    padding: "20px 24px",
    width: "100%",
  },
  button: {
    padding: 8,
    "&:before": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: -24,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      right: -24,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    "&:hover": { color: theme.palette.secondary.main },
  },
}));

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

export default function ContactUs() {
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [submitText, setSubmitText] = useState("submit");
  const mobile = useMediaQuery("(max-width:600px)");

  const handleChange = (field) => (event) => {
    setValues({ ...values, [field]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitText("sending");

    let templateParams = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      message: values.message,
    };

    console.log(config.REACT_APP_SERVICE_ID);
    
    emailjs
      .send(
        config.REACT_APP_SERVICE_ID,
        "form_template",
        templateParams,
        config.REACT_APP_USER_ID
      )
      .then(
        () => {
          setSubmitText("submitted");
        },
        (error) => {
          setSubmitText("something went wrong");
        }
      );
    setTimeout(() => {
      setValues(initialValues);
      setSubmitText("submit");
    }, 2000);
  };

  return (
    <section className={classes.section}>
      <img
        src={logo}
        className={`${classes.logo} ${classes.logoLeft}`}
        alt=""
      />
      {!mobile && (
        <img
          src={logo}
          className={`${classes.logo} ${classes.logoRight} `}
          alt=""
        />
      )}
      <Container maxWidth="lg">
        <Grid container justify="flex-end">
          <Typography variant="h6" color="secondary" className={classes.title}>
            Contact us
          </Typography>
        </Grid>
        <Grid container justify="space-evenly">
          {/* <Grid
            container
            justify="center"
            direction="column"
            item
            sm={3}
            className={classes.card}
          >
            <img src={icon} alt="" className={classes.icon} />
            <Typography variant="h4" color="primary">
              Address
            </Typography>
            <Typography>Lorem ipsum dolor sit amet</Typography>
          </Grid> */}
          <Grid
            container
            justify="center"
            direction="column"
            item
            sm={3}
            className={classes.card}
          >
            <img src={icon} alt="" className={classes.icon} />
            <Typography variant="h4" color="primary">
              Phone
            </Typography>
            <Typography>+1 (323)452-1996</Typography>
          </Grid>
          <Grid
            container
            justify="center"
            direction="column"
            item
            sm={3}
            className={classes.card}
          >
            <img src={icon} alt="" className={classes.icon} />
            <Typography variant="h4" color="primary">
              Email
            </Typography>
            <Typography>carmen@codeandcandor.dev</Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" justify="center">
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid item>
              <TextField
                type="text"
                color="secondary"
                placeholder="Name"
                value={values.name}
                onChange={handleChange("name")}
                required
                className={classes.input}
              />
            </Grid>
            <Grid item>
              <TextField
                type="tel"
                color="secondary"
                placeholder="Phone"
                value={values.phone}
                onChange={handleChange("phone")}
                required
                className={classes.input}
              />
            </Grid>
            <Grid item>
              <TextField
                type="email"
                color="secondary"
                placeholder="Email"
                value={values.email}
                onChange={handleChange("email")}
                required
                className={classes.input}
              />
            </Grid>
            <Grid item>
              <TextField
                color="secondary"
                placeholder="Message"
                value={values.message}
                onChange={handleChange("message")}
                required
                multiline
                rows={3}
                className={classes.input}
              />
            </Grid>
            <Grid item>
              <ButtonBase focusRipple type="submit">
                <Typography
                  color="primary"
                  variant="button"
                  className={classes.button}
                >
                  {submitText}
                </Typography>
              </ButtonBase>
            </Grid>
          </form>
        </Grid>
      </Container>
    </section>
  );
}
