import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import backgrImg from "./../assets/12232.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    backgroundImage: `url(${backgrImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
  },
  column: {
    height: "100%",
    padding: "20px 20px 20px 60px",
    "@media (max-width:960px)": {
      padding: "20px 24px 40px ",
    },
  },
}));

export default function Header() {
  const smallDevice = useMediaQuery("(max-width:960px)");

  const classes = useStyles();

  return (
    <header>
      <Grid container className={classes.container}>
        <Grid
          container
          alignContent={smallDevice ? "flex-end" : "center"}
          item
          sm={8}
          md={5}
          xl={4}
          className={classes.column}
        >
          <Typography variant="h1" color="textSecondary">
            Code & Candor
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Create with Confidence.
          </Typography>
        </Grid>
      </Grid>
    </header>
  );
}
