import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import { Link as ScrollLink } from "react-scroll";

import logo from "./../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "rgba(200,92,187,0)",
  },
  logoContainer: { flex: 1 },
  logo: { height: 48, cursor: "pointer" },
  link: {
    position: "relative",
    color: theme.palette.primary.contrastText,
    fontSize: "1.1rem",
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: 300,
    padding: "0 24px",
    cursor: "pointer",
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: "none",
      color: "white",
      "&:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: 0,
        width: 16,
        height: 1,
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  },
}));

export default function MenuBar() {
  const classes = useStyles();
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY < 100) {
      setOpacity(window.scrollY / 100);
    } else {
      setOpacity(1);
    }
  };

  return (
    <AppBar
      position="fixed"
      className={classes.appbar}
      style={{
        backgroundColor: `rgba(200,92,187,${opacity * 0.9})`,
        boxShadow: `0 1px 3px rgba(0,0,0,${
          0.12 * opacity
        }), 0 1px 2px rgba(0,0,0,${0.24 * opacity})`,
      }}
    >
      <Toolbar color="secondary">
        <div className={classes.logoContainer}>
          <ScrollLink smooth to="header">
            <img src={logo} alt="logo" className={classes.logo} />
          </ScrollLink>
        </div>
        <ScrollLink smooth offset={-112} to="services">
          <Link className={classes.link}>Services</Link>
        </ScrollLink>
        <ScrollLink smooth offset={-40} to="aboutUs">
          <Link className={classes.link}>About us</Link>
        </ScrollLink>
        <ScrollLink smooth offset={-40} to="howItWorks">
          <Link className={classes.link}>How it works</Link>
        </ScrollLink>
        <ScrollLink smooth offset={-20} to="contactUs">
          <Link className={classes.link}>Contact us</Link>
        </ScrollLink>
      </Toolbar>
    </AppBar>
  );
}
