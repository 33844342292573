import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import icon from "./../assets/icon.svg";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 80,
      left: 0,
      zIndex: -100,
      width: "100%",
      height: "calc(100% - 140px)",
      backgroundColor: theme.palette.primary.light,
    },
  },
  container: {
    marginTop: 60,
    marginBottom: 60,
    textAlign: "center",
  },
  title: {
    position: "relative",
    textAlign: "right",
    paddingRight: 24,
    width: "max-content",
    alignSelf: "flex-end",
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: -24,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  cardOuterContainer: {
    marginTop: 60,
  },
  cardContainer: { padding: 16 },
  card: {
    position: "relative",
    padding: 24,
    backgroundColor: theme.palette.background.default,
    borderBottom: `8px solid ${theme.palette.secondary.main}`,
    height: "100%",
    "& h4": {
      marginBottom: 8,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      borderBottom: `60px solid ${theme.palette.background.default}`,
      borderLeft: `40px solid ${theme.palette.primary.light}`,
      width: 0,
    },
  },
  icon: { height: 60, marginBottom: 12 },
}));

export default function Services() {
  const classes = useStyles();

  return (
    <section className={classes.outerContainer}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container justify="flex-end">
          <Grid item sm={6}>
            <Grid container justify="flex-end">
              <Typography
                variant="h6"
                color="secondary"
                className={classes.title}
              >
                Services
              </Typography>
            </Grid>
            {/* <Typography variant="h3" color="primary">
              What we do
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container className={classes.cardOuterContainer}>
          <Grid item sm={6} lg={3} className={classes.cardContainer}>
            <Grid className={classes.card}>
              <img src={icon} alt="" className={classes.icon} />
              <Typography variant="h4" color="primary">
                Frontend Development
              </Typography>
              <Typography>
              We will work with you to bring your vision to life. Or help you refine it, if you’re still a bit unsure.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={3} className={classes.cardContainer}>
            <Grid className={classes.card}>
              <img src={icon} alt="" className={classes.icon} />
              <Typography variant="h4" color="primary">
                Backend Development
              </Typography>
              <Typography>
              Building quality, test-driven, scalable backend solutions is part of our passion and we always deliver.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={3} className={classes.cardContainer}>
            <Grid className={classes.card}>
              <img src={icon} alt="" className={classes.icon} />
              <Typography variant="h4" color="primary">
                App Development
              </Typography>
              <Typography>
              If you have are in the market for app development we currently offer both iOS and Android solutions.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={3} className={classes.cardContainer}>
            <Grid className={classes.card}>
              <img src={icon} alt="" className={classes.icon} />
              <Typography variant="h4" color="primary">
                Salesforce
              </Typography>
              <Typography>
              If your company could benefit from custom Salesforce development we’re happy to have our certified engineers work with you to meet your needs.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
