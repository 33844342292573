import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import icon from "./../assets/icon.svg";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.palette.primary.light,
    padding: "100px 32px",
  },
  title: {
    position: "relative",
    textAlign: "right",
    width: "max-content",
    paddingLeft: 24,
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: 0,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  subtitle: {
    margin: "4px 0 40px",
  },
  cardOuterContainer: { marginTop: 20 },
  cardContainer: {
    position: "relative",
    padding: "24px 24px 28px",
    "@media (max-width:600px)": { padding: "24px 0 28px" },
  },
  card: {
    position: "relative",
    padding: "32px 24px 48px",
    backgroundColor: theme.palette.background.default,
    height: "100%",
    textAlign: "center",
    "& h4": {
      marginBottom: 8,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      borderBottom: `30px solid ${theme.palette.background.default}`,
      borderLeft: `20px solid ${theme.palette.primary.light}`,
      width: 0,
    },
  },
  cardLineRight: {
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      right: -90,
      height: 2,
      width: 90,
      backgroundColor: theme.palette.primary.main,
    },
  },
  cardLineLeft: {
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: -90,
      height: 2,
      width: 90,
      backgroundColor: theme.palette.primary.main,
    },
  },
  cardLineBottom: {
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: -90,
      left: "50%",
      height: 90,
      width: 2,
      backgroundColor: theme.palette.primary.main,
    },
  },
  cardArrowTop: {
    "&:before": {
      content: '""',
      position: "absolute",
      top: -30,
      left: "50%",
      height: 30,
      width: 2,
      backgroundColor: theme.palette.primary.main,
    },
    "&:after": {
      content: '""',
      position: "absolute",
      top: -30,
      left: "50%",
      height: 20,
      width: 2,
      backgroundColor: theme.palette.primary.main,
      transform: "rotate(-45deg) translate(6.5px, 3px)",
    },
  },
  cardArrowBottom: {
    "&:before": {
      content: '""',
      position: "absolute",
      bottom: -36,
      left: "50%",
      height: 36,
      width: 2,
      backgroundColor: theme.palette.primary.main,
    },
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: -30,
      left: "50%",
      height: 20,
      width: 2,
      backgroundColor: theme.palette.primary.main,
      transform: "rotate(45deg) translate(11px, 1px)",
    },
  },
  icon: { height: 60, margin: "12px 0 24px" },
}));

export default function HowItWorks() {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <section className={classes.section}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={3} sm={12}>
            <Typography
              variant="h6"
              color="secondary"
              className={classes.title}
            >
              How it works
            </Typography>
            <Typography
              variant="h3"
              color="primary"
              className={classes.subtitle}
            >
              Our Process
            </Typography>
            <Typography>
             Our Integrity combined with proven development process is the ‘secret sauce’ behind our success.
            </Typography>
          </Grid>
          <Grid
            container
            item
            lg={9}
            sm={12}
            className={classes.cardOuterContainer}
          >
            <Grid item sm={4} className={classes.cardContainer}>
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                className={`${classes.card}`}
              >
                <div
                  className={`${
                    mobile ? classes.cardArrowBottom : classes.cardLineRight
                  }`}
                >
                  <img src={icon} alt="" className={classes.icon} />
                  <Typography variant="h4" color="primary">
                    Discovery
                  </Typography>
                  <Typography>
                  Having all the requirements, along with a deep understanding of your goals for the future allow us to see the big picture.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4} className={classes.cardContainer}>
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                className={`${classes.card}`}
              >
                <div
                  className={`${
                    mobile ? classes.cardArrowBottom : classes.cardLineRight
                  }`}
                >
                  <img src={icon} alt="" className={classes.icon} />
                  <Typography variant="h4" color="primary">
                    Plan
                  </Typography>
                  <Typography>
                  Following the discovery process we agree on timelines and scope of work during each phase of the project.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4} className={classes.cardContainer}>
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                className={`${classes.card}`}
              >
                <div
                  className={`${
                    mobile ? classes.cardArrowBottom : classes.cardLineBottom
                  }`}
                >
                  <img src={icon} alt="" className={classes.icon} />
                  <Typography variant="h4" color="primary">
                    Build
                  </Typography>
                  <Typography>
                  By using the phases defined in the planning stage we are able to track the project very closely. And will always be candid with you during this process.                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4} className={classes.cardContainer}>
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                className={`${classes.card}`}
                style={{ zIndex: 20 }}
              >
                <div
                  className={
                    mobile ? classes.cardArrowBottom : classes.cardArrowTop
                  }
                >
                  <img src={icon} alt="" className={classes.icon} />
                  <Typography variant="h4" color="primary">
                    Test
                  </Typography>
                  <Typography>
                  The testing phase is vital to every project and is also considered during the planning stage, so we are sure to account for everything.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4} className={classes.cardContainer}>
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                className={`${classes.card}`}
                style={{ zIndex: 10 }}
              >
                <div
                  className={
                    mobile ? classes.cardArrowBottom : classes.cardLineLeft
                  }
                >
                  <img src={icon} alt="" className={classes.icon} />
                  <Typography variant="h4" color="primary">
                    Release
                  </Typography>
                  <Typography>
                  We will continue to monitor the product after deployment to ensure you receive the necessary support.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4} className={classes.cardContainer}>
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                className={`${classes.card}`}
              >
                <div className={!mobile && classes.cardLineLeft}>
                  <img src={icon} alt="" className={classes.icon} />
                  <Typography variant="h4" color="primary">
                    Repeat
                  </Typography>
                  <Typography>
                  With new projects or feature requests, we start the process all over again.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
