import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link as ScrollLink } from "react-scroll";

import logo from "./../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    padding: "64px 0",
  },
  container: {
    padding: "0 120px",
    "@media (max-width:600px)": { padding: "0 20px" },
  },
  logo: { height: 100 },
  email: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },
  linkContainer: {
    "@media (max-width:600px)": { marginTop: 36, padding: "0 24px" },
  },
  link: {
    position: "relative",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    opacity: 0.9,
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: -24,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    "&:hover": {
      textDecoration: "none",
      opacity: 1,
      "&:after": { width: 20, left: -28 },
    },
  },
  contactContainer: { "@media (max-width:600px)": { marginTop: 36 } },
}));

export default function ComponentName() {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Grid
            container
            item
            sm={4}
            xs={12}
            justify={mobile ? "center" : "flex-start"}
          >
            <img alt="" src={logo} className={classes.logo} />
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            xs={12}
            className={classes.linkContainer}
          >
            <Grid>
              <Typography variant="h6">
                <ScrollLink
                  smooth
                  offset={-112}
                  to="services"
                  className={classes.link}
                >
                  Services
                </ScrollLink>
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                <ScrollLink
                  smooth
                  offset={-40}
                  to="aboutUs"
                  className={classes.link}
                >
                  About us
                </ScrollLink>
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                <ScrollLink
                  smooth
                  offset={-40}
                  to="howItWorks"
                  className={classes.link}
                >
                  How it works
                </ScrollLink>
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                <ScrollLink
                  smooth
                  offset={-20}
                  to="contactUs"
                  className={classes.link}
                >
                  Contact us
                </ScrollLink>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sm={4}
            xs={12}
            direction="column"
            className={classes.contactContainer}
          >
            <Typography variant="h6" gutterBottom>
              LOCATION AND CONTACT
            </Typography>
            {/* <Typography gutterBottom>Lorem ipsum dolor sit amet</Typography> */}
            <Typography gutterBottom>
              <a href="mailto:carmen@codeandcandor.dev" className={classes.email}>
                carmen@codeandcandor.dev
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
