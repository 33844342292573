import React from "react";
import { Element } from "react-scroll";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MenuBar from "./MenuBar";
import MobileMenuBar from "./MobileMenuBar";
import Header from "./Header";
import Services from "./Services";
import AboutUs from "./AboutUs";
import HowItWorks from "./HowItWorks";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function Home() {
  const smallDevice = useMediaQuery("(min-width:960px)");

 


  return (
    <>
      {smallDevice ? <MenuBar /> : <MobileMenuBar />}
      <Element name="header">
        <Header />
      </Element>
      <Element name="services">
        <Services />
      </Element>
      <Element name="aboutUs">
        <AboutUs />
      </Element>
      <Element name="howItWorks">
        <HowItWorks />
      </Element>
      <Element name="contactUs">
        <ContactUs />
      </Element>
      <Footer />
    </>
  );
}
