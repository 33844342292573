import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Quicksand",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontFamily: [
        "Jost",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontWeight: 600,
      fontSize: "3.125rem",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: [
        "Jost",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontWeight: 600,
      fontSize: "2.75rem",
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: [
        "Jost",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontWeight: 600,
      fontSize: "2.125rem",
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: [
        "Jost",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontWeight: 600,
      fontSize: "1.6rem",
      textTransform: "uppercase",
    },
    h6: {
      fontFamily: [
        "Jost",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontWeight: 400,
      fontSize: "1.25rem",
      textTransform: "uppercase",
    },
    body1: {
      fontSize: "1.125rem",
    },
    button: {
      fontFamily: [
        "Jost",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: "1.5rem",
    },
  },
  palette: {
    background: {
      default: "#FBF3FD",
    },
    primary: {
      light: "#F1E8F3",
      main: "#0F0D50",
      dark: "#000028",
      contrastText: "#F1E8F3",
    },
    secondary: {
      light: "#c85cbb",
      main: "#95298B",
      dark: "#64005d",
      contrastText: "#F1E8F3",
    },
    text: {
      secondary: "#F1E8F3",
    },
  },
});

export default theme;
