import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import imageLeft from "./../assets/various-computer-equipment-with-programming-code-screens-table-dark-room-cyber-security-concept-copy-space.jpg";
import imageRight from "./../assets/hacker-working-using-computer-with-codes.jpg";

const useStyles = makeStyles((theme) => ({
  section: { position: "relative", padding: "100px 0" },
  image: { position: "absolute", height: 500, zIndex: -100, top: 0 },
  imageMobile: { width: "100vw", marginTop: 40 },
  imageLeftContainer: {
    position: "absolute",
    width: "50%",
    height: "100%",
    left: 0,
  },
  imageLeft: {
    right: 0,
    marginRight: 270,
    height: "100%",
  },
  imageRightContainer: {
    position: "absolute",
    width: "50%",
    height: "100%",
    right: 0,
  },
  imageRight: {
    left: 0,
    marginLeft: 270,
    height: "100%",
  },
  titleContainer: {
    position: "relative",
    width: 440,
    margin: "0 auto 80px",
    textAlign: "center",
    "@media (max-width:600px)": { width: "100%", margin: "0 auto 60px" },
  },
  title: {
    position: "relative",
    textAlign: "right",
    width: "max-content",
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: -24,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  subtitle: {
    margin: "4px 0 40px",
  },
  card: { padding: "24px 36px" },
  cardText: {
    position: "relative",
    padding: "12px 0 0 32px",
    "&:after": {
      content: '""',
      position: "absolute",
      top: 26,
      left: 2,
      width: 16,
      height: 1,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export default function AboutUs() {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <section className={classes.section}>
      <Container maxWidth="lg">
        <Grid container justify="center" className={classes.titleContainer}>
          <Typography variant="h6" color="secondary" className={classes.title}>
            About us
          </Typography>
          <Typography variant="h3" color="primary" className={classes.subtitle}>
            Say hello, to the transparent development process.
          </Typography>
          <Typography>
          It’s no secret that developers and consultants are famous for overcharging, shifting scope at the last minute and using technical jargon to bully their clients. 
          </Typography>

          {mobile ? (
            <img src={imageLeft} className={classes.imageMobile} alt="" />
          ) : (
            <>
              <div className={classes.imageLeftContainer}>
                <img
                  src={imageLeft}
                  className={`${classes.image} ${classes.imageLeft}`}
                  alt=""
                />
              </div>
              <div className={classes.imageRightContainer}>
                <img
                  src={imageRight}
                  className={`${classes.image} ${classes.imageRight} `}
                  alt=""
                />
              </div>
            </>
          )}
        </Grid>
        <Grid container>
          <Grid item sm={4} className={classes.card}>
            <Typography variant="h4" color="primary">
              It's about you
            </Typography>
            <Typography className={classes.cardText}>
            Our process isn’t a simple template that everyone goes through. Each solution is customized based on not only your needs but your goals for the future of your company.            </Typography>
          </Grid>
          <Grid item sm={4} className={classes.card}>
            <Typography variant="h4" color="primary">
              Trust
            </Typography>
            <Typography className={classes.cardText}>
            We don’t rush into signing contracts, the process of discovery is very important and allows Code & Candor to fully stand behind our estimates so that together we enter the development process with confidence.
            </Typography>
          </Grid>
          <Grid item sm={4} className={classes.card}>
            <Typography variant="h4" color="primary">
              The right fit
            </Typography>
            <Typography className={classes.cardText}>
            We only take on clients when the fit is just right. No one wants to be in a bad relationship and your happiness is more important than our bottom line.            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
