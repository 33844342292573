import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import { Link as ScrollLink } from "react-scroll";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import logo from "./../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: "rgba(200,92,187,0.9)",
    color: theme.palette.secondary.contrastText,
  },
  logoContainer: { flex: 1 },
  logo: {
    height: "2.2rem",
    margin: "auto",
  },
  logoLink: {
    height: "2.2rem",
    margin: "auto",
  },
  close: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px auto`,
  },
  listIcon: {
    minWidth: "3rem",
  },
  profileIcon: {
    fontSize: "2.5rem",
  },
  list: {
    marginLeft: "auto",
    padding: "0 20px 12px",
    "& span": {
      marginLeft: "auto",
      position: "relative",
      textAlign: "right",
      width: "max-content",
      "&:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: -20,
        width: 12,
        height: 1,
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  },
  listItem: {
    paddingLeft: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.contrastText,
  },
}));

export default function MobileMenuBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY < 100) {
      setOpacity(window.scrollY / 100);
    } else {
      setOpacity(1);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      {!open && (
        <AppBar
          position="fixed"
          style={{
            backgroundColor: `rgba(200,92,187,${opacity * 0.9})`,
            boxShadow: `0 1px 3px rgba(0,0,0,${
              0.12 * opacity
            }), 0 1px 2px rgba(0,0,0,${0.24 * opacity})`,
          }}
        >
          <Toolbar>
            <div className={classes.logoContainer}>
              <ScrollLink smooth to="header">
                <img src={logo} alt="logo" className={classes.logo} />
              </ScrollLink>
            </div>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        anchor="top"
        open={open}
        onClose={toggleDrawer}
        classes={{ paper: classes.paper }}
      >
        <IconButton
          className={classes.close}
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <CloseIcon />
        </IconButton>

        <List className={classes.list}>
          <ScrollLink
            smooth
            offset={-112}
            to="services"
            className={classes.link}
          >
            <ListItem
              button
              className={`${classes.listItem}`}
              onClick={() => {
                setOpen(false);
              }}
            >
              <ListItemText primary="Services" />
            </ListItem>
          </ScrollLink>
          <ScrollLink smooth offset={-40} to="aboutUs" className={classes.link}>
            <ListItem
              button
              className={`${classes.listItem}`}
              onClick={() => {
                setOpen(false);
              }}
            >
              <ListItemText primary="About us" />
            </ListItem>
          </ScrollLink>
          <ScrollLink
            smooth
            offset={-40}
            to="howItWorks"
            className={classes.link}
          >
            <ListItem
              button
              className={`${classes.listItem}`}
              onClick={() => {
                setOpen(false);
              }}
            >
              <ListItemText primary="How it works" />
            </ListItem>
          </ScrollLink>
          <ScrollLink
            smooth
            offset={-20}
            to="contactUs"
            className={classes.link}
          >
            <ListItem
              button
              className={`${classes.listItem}`}
              onClick={() => {
                setOpen(false);
              }}
            >
              <ListItemText primary="Contact us" />
            </ListItem>
          </ScrollLink>
        </List>
      </Drawer>
    </>
  );
}
